import { useState, CSSProperties } from 'react'
import styles from '@/styles/components/carousel-3d/Slide.module.scss'
import cn from 'classnames'

interface Props {
  index: number
  parent: ParentProps
  content: JSX.Element,
  onClick?: () => void
}

interface ParentProps {
  count: number,
  perspective: number,
  display?: number,
  loop?: boolean,
  width: number,
  dragXOffset: number,
  startIndex?: number,
  disable3d: boolean,
  divisor: number,
  currentIndex: number,
  inverseScaling: number,
  animationSpeed: number,
  total: number,
  rightIndices: () => number[],
  leftIndices: () => number[],
  rightOutIndex: () => number,
  leftOutIndex: () => number,
  hasHiddenSlides: () => boolean,
  slideWidth: () => number,
  slideHeight: () => number,
  visible: () => number
}

export const Slide: React.FC<Props> = ({ index, parent, content }) => {
  const [ zIndex ] = useState(0)

  function isCurrent(i: number) {
    return i === parent.currentIndex
  }

  function leftIndex() {
    if (getSideIndex(parent.leftIndices()) > parent.currentIndex - 1) return -1
    return getSideIndex(parent.leftIndices())
  }

  function rightIndex() {
    if (getSideIndex(parent.rightIndices()) > parent.total - parent.currentIndex - 2) return -1
    return getSideIndex(parent.rightIndices())
  }

  function getSideIndex(array: Array<number>) {
    let index = -1

    array.forEach((pos, i) => {
      if (matchIndex(pos)) {
        index = i
      }
    })
    return index
  }

  function matchIndex(idx: number) {
    return (idx >= 0) ? index === idx : (parent.total + idx) === index
  }

  function numberOfSideSlides() {
    return Math.floor(parent.visible() / 2)
  }

  function missedSlidesOnLeftSide() {
    return numberOfSideSlides() - parent.currentIndex
  }

  function isMissedOnLeftSide() {
    return !!missedSlidesOnLeftSide()
  }

  function missedSlidesOnRightSide() {
    return parent.currentIndex - (parent.total - 1) + numberOfSideSlides()
  }

  function isMissedOnRightSide() {
    return !!missedSlidesOnRightSide()
  }

  function isMissedLeftSlide() {
    return parent.total - index <= missedSlidesOnLeftSide()
  }

  function isMissedRightSlide() {
    return index < missedSlidesOnRightSide()
  }

  function slideStyle(): CSSProperties {
    let styles: CSSProperties = {
      opacity: 0,
      visibility: 'hidden',
      transform: '',
      top: 0,
      zIndex: 0
    }

    if (!isCurrent(index)) {
      let slideIndexPosition = 0

      if (isMissedOnLeftSide() && isMissedLeftSlide()) {
        slideIndexPosition = index - parent.total - parent.currentIndex
        styles.opacity = 1
        styles.visibility = 'visible'

      } else if (isMissedOnRightSide() && isMissedRightSlide()) {

        slideIndexPosition = parent.total - parent.currentIndex + index
        styles.opacity = 1
        styles.visibility = 'visible'

      } else {
        const candidateIndex = -(parent.currentIndex - index)

        if (candidateIndex < 0) {
          slideIndexPosition = Math.max(candidateIndex, -numberOfSideSlides())
        } else {
          slideIndexPosition = Math.min(candidateIndex, numberOfSideSlides())
        }

        if (isVisible()) {
          styles.opacity = 1
          styles.visibility = 'visible'
        }
      }

      styles = Object.assign(styles, calculatePosition(slideIndexPosition, slideIndexPosition > 0 ? true : false, zIndex))
    } else {
      styles.transform = 'translateX(' + (-Math.round(parent.dragXOffset)) + 'px)'
    }

    styles.width = parent.slideWidth() + 'px',
    styles.height = parent.slideHeight() + 'px',
    styles.transition = ' transform ' + parent.animationSpeed + 'ms, ' +
    '                     opacity ' + parent.animationSpeed + 'ms, ' +
    '                     visibility ' + parent.animationSpeed + 'ms,' +
    '                     filter ' + parent.animationSpeed + 'ms'

    return styles
  }

  function calculatePosition(i: number, positive: boolean, zIndex: number) {
    const z = !parent.disable3d ? parent.inverseScaling + (Math.abs(i) * 100) : 0
    const y = !parent.disable3d ? parent.perspective : 0
    const leftRemain = Math.abs(i) * (parent.width / parent.divisor || 1.2)

    const transform = (positive)
      ? 'translate3d(' + (leftRemain - parent.dragXOffset) + 'px, 0, 0) translate3d(0,0,-' + (z - parent.dragXOffset) + 'px) ' +
      'rotateY(-' + y + 'deg)'
      : 'translate3d(-' + (leftRemain + parent.dragXOffset) + 'px, 0, 0) translate3d(0,0,-' + (z + parent.dragXOffset) + 'px) ' +
      'rotateY(' + y + 'deg)'

    return {
      transform: transform,
      zIndex: zIndex - Math.abs(i)
    }
  }

  function isVisible() {
    return Math.abs(parent.currentIndex - index) <= numberOfSideSlides()
  }

  function computedClasses() {
    return cn({
      [`left-${leftIndex() + 1}`]: leftIndex() >= 0,
      [`right-${rightIndex() + 1}`]: rightIndex() >= 0,
      [styles.carousel_3d_slide_current]: isCurrent(index)
    })
  }

  return (
    <div className={`${styles.carousel_3d_slide} ${computedClasses()}`} style={slideStyle()}>
      {content}
    </div>
  )
}
