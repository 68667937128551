import styles from '@/styles/components/common/Accordion.module.scss'
import { useState, useRef, useEffect } from 'react'
import parse from 'html-react-parser'

interface Props {
  item: Item,
  toggleBlack?: boolean,
  activeSec?: boolean,
  smallButton?: boolean,
  onClose?: (title: string | JSX.Element) => void,
  onOpen?: (title: string | JSX.Element) => void
}

interface Item {
  title: string | JSX.Element,
  titleSize?: number,
  text: string,
  active: boolean,
  component?: JSX.Element;
  itemClass?: string,
  smallButton?: boolean,
  buttonClass?: string
}

export const AccordionItem: React.FC<Props> = ({ item, onClose, onOpen, toggleBlack = false, smallButton = false, activeSec = false }) => {
  const [ itemState, toggleActive ] = useState(item)
  const [ height, setHeight ] = useState(0)
  const content = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (itemState.active) {
      setTimeout(() => {
        if (content.current) {
          setHeight(content.current.scrollHeight)
        }
      }, 300) // Delay for a correct scrollHeight fetch
    } else {
      setHeight(0)
    }
  }, [itemState.active])

  return (
    <dl className={`${styles.accordion__item} ${itemState.itemClass || ''}`}>
      <dt>
        <button className={`${styles.accordion__button} ${smallButton ? styles.accordion__button_small : ''} ${itemState.buttonClass || ''}`} onClick={() => {
          toggleActive((prevItem: Item) => {
            if (prevItem.active) {
              onClose && onClose(item.title)
            } else {
              onOpen && onOpen(item.title)
            }

            return {
              ...prevItem,
              active: prevItem.active ? false : true
            }
          })
        }}>
          <p style={{fontSize: itemState.titleSize ? itemState.titleSize + 'px' : undefined}} className={`${styles.accordion__title} ${itemState.active ? activeSec ? styles.accordion__toggle_active_sec : styles.accordion__title_active : ''}`}>{itemState.title}</p>
          <span className={`${styles.accordion__toggle} ${smallButton ? styles.accordion__toggle_small : ''} ${toggleBlack ? styles.accordion__toggle_black : ''} ${itemState.active ? styles.accordion__toggle_active : ''}`}></span>
        </button>
      </dt>
      <dd>
        <p
          ref={content}
          className={`${styles.accordion__text} ${itemState.active ? styles.accordion__text_active : ''}`}
          style={{height: height + 'px'}}
        >{ itemState.component || parse(itemState.text)}</p>
      </dd>
    </dl>
  )
}
