import { AccordionItem } from './AccordionItem'
import styles from '@/styles/components/common/Accordion.module.scss'

interface Props {
  items: Item[],
  toggleBlack?: boolean,
  activeSec?: boolean,
  smallButton?: boolean,
  onClose?: (title: string | JSX.Element) => void,
  onOpen?: (title: string | JSX.Element) => void
}

interface Item {
  title: string | JSX.Element,
  text: string,
  titleSize?: number,
  smallButton?: boolean,
  component?: JSX.Element;
  active: boolean,
  itemClass?: string,
  buttonClass?: string
}

export const Accordion: React.FC<Props> = ({ items, onClose, onOpen, toggleBlack = false, smallButton = false, activeSec = false }) => {
  return (
    <div className={styles.accordion}>
      {
        items.map((item, i) => {
          return (
            <AccordionItem
              item={item}
              smallButton={smallButton}
              activeSec={activeSec}
              toggleBlack={toggleBlack}
              onClose={onClose}
              onOpen={onOpen}
              key={i}
            />
          )
        })
      }
    </div>
  )
}
