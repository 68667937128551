import styles from '@/styles/components/carousel-3d/Bullets.module.scss'
import cn from 'classnames'

interface Props {
  total: number,
  current: number,
  dark?: boolean,
  gray?: boolean
}

export const Bullets: React.FC<Props> = ({
  total,
  current,
  gray = false
}) => {
  const classes = cn({
    [styles.carousel_3d_bullets]: true
  })

  const bulletClasses = (n: number) => {
    if (n === current) return styles.carousel_3d_bullets_item_current
    if (n - 1 === current) return styles.carousel_3d_bullets_item_after_current
    if (n + 1 === current) return styles.carousel_3d_bullets_item_before_current
  }

  return (
    <div className={classes}>
      {
        // @ts-ignore
        [...Array(total).keys()].map((n) => {
          return <span key={n} className={`${styles.carousel_3d_bullets_item} ${gray ? styles.carousel_3d_bullets_item_gray : null} ${bulletClasses(n)}`}></span>
        })
      }
    </div>
  )
}
