import styles from '@/styles/components/carousel-3d/Carousel3d.module.scss'
import { useState, useRef, useEffect } from 'react'
import { Slide } from './Slide'
import { Controls } from './Controls'
import { Bullets } from './Bullets'

export interface Props {
  count: number,
  perspective?: number,
  display?: number,
  loop?: boolean,
  divisor?: number,
  controlsVisible?: boolean,
  dir?: string,
  width?: number,
  height?: number,
  minSwipeDistance?: number,
  startIndex?: number,
  inverseScaling?: number,
  animationSpeed?: number,
  nextHtml?: JSX.Element | undefined,
  prevHtml?: JSX.Element | undefined,
  bias?: 'left' | 'right',
  slides: Slide[],
  bullets?: boolean,
  grayBullets?: boolean,
  disable3d?: boolean,
  className?: string,
  onSlideChange?(index: number): void,
  onLastSlide?(index: number): void
}

interface Slide {
  content: JSX.Element
}

const noop = () => {
  return
}

export const Carousel3d: React.FC<Props> = ({
  count = 0,
  perspective = 35,
  startIndex = -1,
  width = 360,
  height = 270,
  loop = true,
  display = 5,
  controlsVisible = false,
  nextHtml = undefined,
  prevHtml = undefined,
  animationSpeed = 500,
  inverseScaling = 400,
  minSwipeDistance = 50,
  divisor = 1.2,
  dir = 'rtl',
  bias = 'left',
  className = '',
  disable3d = false,
  bullets = false,
  grayBullets = false,
  slides,
  onSlideChange = noop,
  onLastSlide = noop
}) => {
  const [ mousedown, setMouseDown ] = useState(false)
  const [ dragOffset, setDragOffset ] = useState({ x: 0, y: 0 })
  const [ dragStart, setDragStart ] = useState({ x: 0, y: 0 })
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ total, setTotal ] = useState(0)
  const [ viewport, setViewport ] = useState(0)
  const container = useRef<HTMLDivElement>(null)

  function visible() {
    return (display > total) ? total : display
  }

  function hasHiddenSlides() {
    return total > visible()
  }

  function isLastSlide () {
    return currentIndex === total - 1
  }

  function isFirstSlide () {
    return currentIndex === 0
  }

  function isNextPossible () {
    return !(!loop && isLastSlide())
  }

  function isPrevPossible () {
    return !(!loop && isFirstSlide())
  }

  function leftIndices() {
    let n = (visible() - 1) / 2
    n = (bias.toLowerCase() === 'left' ? Math.ceil(n) : Math.floor(n))

    const indices = []

    for (let m = 1; m <= n; m++) {
      indices.push((dir === 'ltr')
        ? (currentIndex + m) % (total)
        : (currentIndex - m) % (total))
    }

    return indices
  }

  function rightIndices() {
    let n = (visible() - 1) / 2
    n = (bias.toLowerCase() === 'right' ? Math.ceil(n) : Math.floor(n))

    const indices = []

    for (let m = 1; m <= n; m++) {
      indices.push((dir === 'ltr')
        ? (currentIndex - m) % (total)
        : (currentIndex + m) % (total))
    }

    return indices
  }

  function leftOutIndex() {
    let n = (visible() - 1) / 2
    n = (bias.toLowerCase() === 'left' ? Math.ceil(n) : Math.floor(n))
    n++

    if (dir === 'ltr') {
      return ((total - currentIndex - n) <= 0)
        ? -(total - currentIndex - n)
        : (currentIndex + n)
    } else {
      return (currentIndex - n)
    }
  }

  function rightOutIndex() {
    let n = (visible() - 1) / 2
    n = (bias.toLowerCase() === 'right' ? Math.ceil(n) : Math.floor(n))
    n++

    if (dir === 'ltr') {
      return (currentIndex - n)
    } else {
      return ((total - currentIndex - n) <= 0)
        ? -(total - currentIndex - n)
        : (currentIndex + n)
    }
  }

  function getSlideCount() {
    return slides.length
  }

  function goNext() {
    if (isNextPossible()) {
      isLastSlide() ? goSlide(0) : goSlide(currentIndex + 1)
    }
  }

  function goPrev() {
    if (isPrevPossible()) {
      isFirstSlide() ? goSlide(total - 1) : goSlide(currentIndex - 1)
    }
  }

  function goSlide(index: number) {
    setCurrentIndex((index < 0 || index > total - 1) ? 0 : index)

    if (isLastSlide()) {
      onLastSlide(currentIndex)
    }

    setTimeout(() => animationEnd(), animationSpeed)
  }

  function animationEnd() {
    onSlideChange(currentIndex)
  }

  function computeData() {
    setTotal(getSlideCount())

    if (container && container.current) {
      setViewport(container.current.clientWidth)
    }

    if (startIndex > -1) {
      setCurrentIndex(startIndex)
    }
  }

  function attachEventListeners() {
    window.addEventListener('resize', setSize)

    if (container.current) {
      if ('ontouchstart' in window) {
        container.current.addEventListener('touchstart', handleMousedown)
        container.current.addEventListener('touchend', handleMouseup)
        container.current.addEventListener('touchmove', handleMousemove)
      } else {
        container.current.addEventListener('mousedown', handleMousedown)
        container.current.addEventListener('mouseup', handleMouseup)
        container.current.addEventListener('mousemove', handleMousemove)
      }
    }
  }

  function detachEventListeners() {
    window.removeEventListener('resize', setSize)

    if (container.current) {
      if ('ontouchstart' in window) {
        container.current.removeEventListener('touchstart', handleMousedown)
        container.current.removeEventListener('touchend', handleMouseup)
        container.current.removeEventListener('touchmove', handleMousemove)
      } else {
        container.current.removeEventListener('mousedown', handleMousedown)
        container.current.removeEventListener('mouseup', handleMouseup)
        container.current.removeEventListener('mousemove', handleMousemove)
      }
    }
  }

  function handleMouseup() {
    setMouseDown(false)
    setDragOffset({
      x: 0,
      y: 0
    })
  }

  function handleMousedown(e: MouseEvent | TouchEvent) {
    if (isMouseEvent(e)) {
      e.preventDefault()

      setDragStart({
        x: e.clientX,
        y: e.clientY
      })
    }

    if (isTouchEvent(e)) {
      setDragStart({
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      })
    }

    setMouseDown(true)
  }

  function handleMousemove(e: MouseEvent | TouchEvent) {
    if (!mousedown) {
      return
    }

    let eventPosX = 0
    let eventPosY = 0

    if (isMouseEvent(e)) {
      eventPosX = e.clientX
      eventPosY = e.clientY
    }

    if (isTouchEvent(e)) {
      eventPosX = e.touches[0].clientX
      eventPosY = e.touches[0].clientY
    }

    const deltaX = (dragStart.x - eventPosX)
    const deltaY = (dragStart.y - eventPosY)

    if (deltaX === 0) return

    const slope = Math.round(Math.abs(deltaY / deltaX + 0.1) * 10) / 10

    if (slope > 0.5) return

    setDragOffset({ x: deltaX, y: deltaY })

    if (deltaX > minSwipeDistance) {
      handleMouseup()
      goNext()
    } else if (deltaX < -minSwipeDistance) {
      handleMouseup()
      goPrev()
    }
  }

  function isTouchEvent(e: TouchEvent | MouseEvent): e is TouchEvent {
    return e && 'touches' in e
  }

  function isMouseEvent(e: TouchEvent | MouseEvent): e is MouseEvent {
    return e && 'screenX' in e
  }

  function setSize() {
    if (container.current) {
      container.current.style.cssText += 'height:' + slideHeight() + 'px;'
    }
  }

  function slideHeight() {
    const ar = calculateAspectRatio(width, height)
    return slideWidth() / ar
  }

  function slideWidth() {
    return viewport < width ? viewport : width
  }

  function calculateAspectRatio (width: number, height: number) {
    return Math.min(width / height)
  }

  useEffect(() => {
    attachEventListeners()
    return () => {
      detachEventListeners()
    }
  })

  useEffect(() => {
    computeData()
  }, [count])

  useEffect(() => {
    setSize()
  }, [viewport])

  return (
    <div className={`${className} ${styles.carousel_3d_container}`} ref={container}>
      <div className={styles.carousel_3d_slider} style={{ width: slideWidth() + 'px', height: slideHeight() + 'px' }}>
        {slides.map((slide, i) =>
          <Slide
            key={i}
            index={i}
            content={slide.content}
            parent={{
              currentIndex,
              total,
              animationSpeed,
              width,
              count,
              perspective,
              dragXOffset: dragOffset.x,
              startIndex,
              divisor,
              loop,
              disable3d,
              inverseScaling,
              leftIndices,
              rightIndices,
              leftOutIndex,
              rightOutIndex,
              hasHiddenSlides,
              slideWidth,
              slideHeight,
              visible
            }}
          />)}
      </div>
      {controlsVisible && <Controls
        nextHtml={nextHtml}
        prevHtml={prevHtml}
        isPrevPossible={isPrevPossible}
        isNextPossible={isNextPossible}
        goNext={goNext}
        goPrev={goPrev}
      />}
      {bullets && <Bullets
        total={total}
        current={currentIndex}
        gray={grayBullets}
      />}
    </div>
  )
}
