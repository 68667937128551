import styles from '@/styles/components/carousel-3d/Controls.module.scss'
import cn from 'classnames'

interface Props {
  nextHtml: JSX.Element | undefined,
  prevHtml: JSX.Element | undefined,
  isPrevPossible: () => boolean,
  isNextPossible: () => boolean,
  goNext: () => void,
  goPrev: () => void
}

export const Controls: React.FC<Props> = ({
  nextHtml = undefined,
  prevHtml = undefined,
  isPrevPossible,
  isNextPossible,
  goNext,
  goPrev
}) => {
  const prevClass = cn({
    'carousel_3d_controls_prev': true,
    [styles.carousel_3d_controls_disabled]: !isPrevPossible()
  })

  const nextClass = cn({
    'carousel_3d_controls_next': true,
    [styles.carousel_3d_controls_disabled]: !isNextPossible()
  })

  return (
    <div className={styles.carousel_3d_controls}>
      <div className={prevClass} onClick={goPrev}>
        {prevHtml}
      </div>
      <div className={nextClass} onClick={goNext}>
        {nextHtml}
      </div>
    </div>
  )
}
